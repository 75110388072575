import Dashboard from './components/Dashboard';
import LoginForm from './components/LoginForm';
import './App.css';

function App() {
  return (
    <>
    <Dashboard />
    <h3 className="text-white">THE SITE CURRENTLY HAS NO BACKEND BECAUSE ITS STILL IN PRODUCTION</h3>
    </>
  );
}

export default App;
